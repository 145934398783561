import farra from '.././assets/png/farra.png';
import favily from '.././assets/png/favily.png';
import lovebox from '.././assets/png/lovebox.png';
import placeholder from '.././assets/png/placeholder.png';

export const projectsData = [
    {
        id: 1,
        name: 'Farra',
        subTitle: 'Appli qui facilite la mobilité douce en ville',
        stats: '100 000 téléchargements',
        description: 'Application qui promeut la mobilité durable en offrant des services aux utilisateur·ice·s des systèmes de location de vélo publics : inscription au service, réservation de vélos, affichage des stations, suggestion d’itinéraires, affichage des points d’intérêts, etc.',
        technos: ['React Native', 'NodeJS' ,'GraphQL', 'MongoDB'],
        screenshot: farra,
        url: 'https://farra.app/',
        ios: 'https://apps.apple.com/fr/app/farra-v%C3%A9lo-en-ville-facile/id6449237016',
        android: 'https://play.google.com/store/apps/details?id=app.farra&pli=1',
    },
    {
        id: 3,
        name: 'Rent Waves (en cours)',
        subTitle: 'Louer un surf facilement',
        stats: 'En cours de conception',
        description: 'Application mobile permettant de louer des surfs dans des box accessibles 24/7.',
        technos: ['React Native', 'React' ,'Firebase'],
    },
    {
        id: 4,
        name: 'Favily',
        subTitle: 'Réseau social via un widget',
        stats: '500 inscriptions quotidiennes',
        description: 'Appli lancée en septembre 2022 permettant aux utilisateur·ice·s d\'envoyer de beaux messages sur l\'écran d\'accueil de leurs proches, via un widget.',
        technos: ['React Native', 'NodeJS' ,'GraphQL', 'MongoDB'],
        screenshot: favily,
        ios: 'https://apps.apple.com/fr/app/favily-widget-photos-souvenirs/id1638104484',
        android: 'https://play.google.com/store/apps/details?id=love.lovebox.widget&hl=fr&gl=US',
    },
    {
        id: 5,
        name: 'Lovebox',
        subTitle: 'Réseau social via un objet connecté',
        stats: '100 000 utilisations quotidiennes',
        description: 'Appli couplée avec un objet connecté, permettant d’envoyer des messages affichés sur l’objet.',
        technos: ['React Native', 'NodeJS' ,'Typescript'],
        screenshot: lovebox,
        url: 'https://fr.lovebox.love/pages/app',
        ios: 'https://apps.apple.com/fr/app/lovebox/id1154499750',
        android: 'https://play.google.com/store/apps/details?id=love.lovebox.loveboxapp&hl=fr&gl=US',
    },
    {
        id: 2,
        name: 'Split it!',
        subTitle: 'Gérer les dépenses entre amis',
        stats: 'En cours de développement',
        description: 'Application mobile et web pour gérer les dépenses entre amis.',
        technos: ['React Native', 'React' ,'Firebase'],
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/